<template>
  <div>
    <alert-banner v-if="isBannerVisible" id="alert-banner" class="boi-banner">
      Stay informed about the latest updates to
      <div
        class="pl-1 pr-1"
        @click="logBoiBannerInteraction('boi-faqs-page')"
      >
        <router-link to="/faq/621379ca-3fb0-40e4-be32-a95a2ef2e11f">
          BOI filing requirements.
        </router-link>
      </div>
      <button
        v-b-modal.boi-confirmation-modal
        type="button"
        aria-label="Dismiss BOI Banner"
        class="close close-banner"
      >
        x
      </button>
    </alert-banner>
    <b-modal
      id="boi-confirmation-modal"
      ref="BoiConfirmationModal"
      title="Dismiss BOI Banner"
      centered
      size="md"
      hide-footer
    >
      <div class="modal-body">
        <div class="mb-2 text-center">
          <p>Are you sure you want this banner dismissed from your account?</p>
        </div>
        <br>
        <div class="text-center btn-container">
          <button
            class="btn mr-2 btn-secondary"
            aria-label="Cancel button"
            @click="closeBoiConfirmationModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary text-nowrap"
            aria-label="Confirm button"
            @click="confirmDismissal"
          >
            Confirm
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'BoiFaqsBanner',
  components: {
    AlertBanner: () => import('@/components/shared/AlertBanner.vue'),
  },
  data() {
    return {
      isBannerVisible: true,
    }
  },
  computed: {
    ...mapGetters('account', {
      websiteId: 'websiteId',
    }),
    ...mapGetters('session', {
      token: 'getToken',
    }),
    hideIfRoute() {
      const hiddenRoutes = [
        'stageline-required-info',
        'stageline-v2-required-info',
        'stageline-post-payment',
        'stageline-v2-post-payment',
        'verify-order',
      ]

      const hiddenSlideLayoutTypes = [
        'verifyOrder',
      ]

      return !!(hiddenRoutes.includes(this.$route.name)) || hiddenSlideLayoutTypes.includes(this.currentSlide?.layout_type)
    },
    excludedWebsites() {
      // These IDs are temporary. This file will be deleted at the end of the year.
      return [
        'f8ce53f4-bf5a-49a4-b33a-f5e4e081de10', // www.rvmail.com
        '4e597cd7-7f51-4567-9ac8-c61fae318b5b', // www.sasquatchmail.com
        'de1d12e8-6b33-458b-872c-57471564960e', // www.newyorkllcs.com
        'fe4d2044-c374-4ec0-9dde-59f3fedaf7af', // www.lawoncall.com
        '80cf0591-6342-419a-adb9-f7063351f6dd', // www.formed.com
        '8916fbd4-39d1-4e51-82b8-c828d9cd5a93', // www.floridaregisteredagent.com
        'e8c6f7ce-f775-4c43-8628-abc428fe1b1e', // www.floridaregisteredagent.net
        'f876dd71-625b-410a-bfff-049d6da7e011', // www.indianaregisteredagent.com
      ].includes(this.websiteId)
    },
    showBanner() {
      return this.token &&
        !this.excludedWebsites &&
        !this.hideIfRoute &&
        Cookies.get('bannerClosed') !== 'true'
    },
  },
  mounted() {
    this.isBannerVisible = this.showBanner
  },
  methods: {
    closeBoiConfirmationModal() {
      this.$refs.BoiConfirmationModal.hide()
    },
    async confirmDismissal() {
      this.closeBoiConfirmationModal()
      this.isBannerVisible = false
      Cookies.set('bannerClosed', 'true', {
        expires: 365,
        sameSite: 'Lax',
      })
    },
    async logBoiBannerInteraction(location) {
      await createOrFindClientInteractionLog({
        category: 'page-interaction',
        subCategory: location,
        interaction: {
          type: 'button',
          action: 'redirect',
          name: 'vehicle-boi-faqs-banner',
        },
        incompleteLogFromToday: true,
      })
    },
  },
}

</script>

<style lang="scss">

$primary: #283d96;
$primary-dark: darken($primary, 10%);
$yellow: #fff0b0;

.alert-banner-component.boi-banner {
  margin-bottom: .5rem;
  background-color: $yellow;
  color: $primary-dark;

  a {
    color: $primary;
  }
}

.centered {
  text-align: center;
}

.close-banner {
  position: absolute;
  right: 1rem;
}
.btn-container {
  display: flex;
}

.btn-container button {
  flex: 1;
}


@media (max-width: 768px) {
  .boi-banner {
    flex-direction: column;
  }

  .hidden-xs {
    display: none;
  }
}

@media print {
  .alert-banner-component.boi-banner {
    display: none;

    div {
      display: none
    }
  }
}

</style>
